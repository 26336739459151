import { BASE_PATH } from "../utils/constants";
import {BaseService} from 'base-service-fran-dev';

export class ProductService extends BaseService{
    constructor(){
      const baseUrl = BASE_PATH;
      const endPoint = 'catalog/product'; 
      super(baseUrl, endPoint)
    }
  }
export class ProductExcelService extends BaseService{
    constructor(){
      const baseUrl = BASE_PATH;
      const endPoint = "catalog/product/import/excel"; 
      super(baseUrl, endPoint)
    }
  }

export class ProductModelService extends BaseService{
  constructor(){
    const baseUrl = BASE_PATH;
    const endPoint = 'catalog/product_model'; 
    super(baseUrl, endPoint)
  }
}

export class ProductModelAvailableService extends BaseService{
  constructor(){
    const baseUrl = BASE_PATH;
    const endPoint = 'inventory/inventory/presentation';
    super(baseUrl, endPoint)
  }
}


  
  
