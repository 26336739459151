import React, { useEffect, useState } from "react";
import Container from "../../layout/gridContainer";
import { useTheme } from "@mui/material/styles";
import { Grid, Typography } from "@mui/material";
import Transfers from "./operations/transfers/transfers"
import Scrap from "./operations/scrap/scrap"



const InventoryScrap = () => {
	const theme = useTheme();
	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);

	return (
		<Container>
			<Grid container spacing={2}>

				<Grid item xs={12}>
				<Scrap/>
				</Grid>
			</Grid>
		</Container>
	);
}





export default InventoryScrap;
