import * as React from "react";
import Popover from "@mui/material/Popover";
import Button from "@mui/material/Button";
import { Box, Grid, IconButton, Typography } from "@mui/material";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";
import { useState } from "react";
import { useEffect } from "react";
import { useAuth } from "user-context-fran-dev";
import { useNavigate } from "react-router-dom";
import BusinessManages, {
  BusinessMetaData,
  BusinessSettings,
} from "../api/businessManages";
import ColorLensOutlinedIcon from "@mui/icons-material/ColorLensOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import LibraryBooksOutlinedIcon from "@mui/icons-material/LibraryBooksOutlined";
import PolicyOutlinedIcon from "@mui/icons-material/PolicyOutlined";
import { GenericDrawer } from "./keskiCreator/genericDrawer";
import MetaData from "./landing/metaData";
import { ModalImages } from "../pages/landing/modalImages/modalImages";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";

export default function BasicPopover({ children, title, menu, view, color }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  //****************LandinMetaData********************** */

  const [business, setBusiness] = useState("");
  const [styles, setStyles] = useState("");
  const { logout } = useAuth();
  const [openModal, setOpenModal] = useState(false);
  const [openImages, setOpenImages] = useState(false); // Nuevo estado para ModalImages
  const [modalContext, setModalContext] = useState(""); // Nueva bandera

  const [openDrawer, setOpenDrawer] = useState({
    top: false,
    top1: false,
    right: false,
    right1: false,
  });

  const toggleDrawer = (anchor, open) => {
    setOpenDrawer((prevState) => ({
      ...prevState,
      [anchor]: open, // Esto actualizará solo la clave correspondiente
    }));
  };

  const navigate = useNavigate();

  useEffect(() => {
    UpdateBusinessInfo();
    UpdateStyles();
    window.localStorage.setItem("menu", menu);
  }, []);

  const UpdateBusinessInfo = () => {
    (async () => {
      let useBusines = new BusinessMetaData();
      let response = await useBusines.getAllById("1");
      setBusiness(response.data);
    })();
  };

  const getTurnBusiness = async () => {
    try {
      let useBusiness = new BusinessManages();
      let response = await useBusiness.getAllAuth(logout);
      return response.data.turn_business;
    } catch (error) {
      console.error("Error al obtener el turn_business:", error);
      return null;
    }
  };

  const handleClickOpenModal = async () => {
    if (view === "Legal") {
      navigate("/landing/politics");
    } else if (view === "Libreria") {
      const turnBusiness = await getTurnBusiness();
      setBusiness((prev) => ({ ...prev, turn_business: turnBusiness }));
      setModalContext("Personalizar"); // Establecer el contexto
      setOpenImages(true);
    } else {
      toggleDrawer("right", true); // Cierra el drawer derecho
    }
  };

  useEffect(() => { }, [modalContext]);

  const UpdateStyles = () => {
    (async () => {
      let useBusines = new BusinessSettings();
      let response = await useBusines.getAllByIdAuth("1", logout);
      setStyles(response.data);
    })();
  };

  const handleCloseImages = () => {
    setOpenImages(false); // Cambia el estado a false para cerrar el modal
  };

  //************************************************** */
  return (
    <div>
      <Button
        aria-describedby={id}
        variant="text"
        sx={{ color: { color }, height: "45px", textTransform: "none" }}
        onClick={handleClickOpenModal}
      >
        {title}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box
          sx={{
            width: "180px",
            height: "80px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "flex-start",
            paddingLeft: "10px",
            textAlign: "left",
          }}
        >
          <Button
            variant="text"
            onClick={handleClickOpenModal}
            sx={{
              color: "black",
              width: "95%",
              gap: "20px",
              display: "flex",
              justifyContent: "flex-start",
              textTransform: "none",
              "&:hover": {
                backgroundColor: "rgba(186, 85, 211, 0.1)",
                color: "black",
              },
            }}
          >
            {view === "Estilo" && (
              <>
                <ColorLensOutlinedIcon /> Estilo{" "}
              </>
            )}
            {view === "Libreria" && (
              <>
                <LibraryBooksOutlinedIcon /> Librería{" "}
              </>
            )}

            {view === "Información" && (
              <>
                <InfoOutlinedIcon /> Información
              </>
            )}
            {view === "Legal" && (
              <>
                <PolicyOutlinedIcon /> Legal
              </>
            )}
          </Button>
        </Box>
      </Popover>
      <GenericDrawer
        open={openDrawer}
        toggleDrawer={toggleDrawer}
        anchor="right"
        drawerWidth="30%"
      >
        <Grid container sx={{ padding: "16px" }}>
          <Grid
            item
            xs={12}
            sx={{
              paddingTop: "20px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              <strong>{title}</strong>
            </Typography>

            <IconButton onClick={() => toggleDrawer("right", false)}>
              <CloseSharpIcon fontSize="small" />
            </IconButton>
          </Grid>
        </Grid>
        {business && (
          <MetaData
            data={business}
            update={UpdateBusinessInfo}
            updateStyles={UpdateStyles}
            styles={styles}
            view={view}
          />
        )}
      </GenericDrawer>
      {view === "Libreria" && openImages && (
        <Grid item xs={12} mt="16px">
          <ModalImages
            data={business.library} // Asegúrate de que los datos estén disponibles
            turnBusiness={business.turn_business}
            UpdateInfo={UpdateBusinessInfo}
            open={openImages}
            onClose={handleCloseImages}
            context={modalContext} // Pasar el contexto
          />
        </Grid>
      )}
    </div>
  );
}
