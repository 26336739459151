import CircularProgress from "@mui/material/CircularProgress";
import { createContext, useEffect, useState } from "react";
import { DomainList } from "../api/landing/domainList";
import BusinessManages, { BusinessSubscription } from "../api/businessManages";
import { Box } from "@mui/material";
import { useAuth } from "user-context-fran-dev";

export const CreatorContext = createContext({
  creatorRouter: false,
  setCreatorRouter: () => null,
});

export const CreatorProvider = ({ children }) => {
  const [creatorRouter, setCreatorRouter] = useState(null);
  const [unique_id, setUnique_id] = useState(null);
  const { logout, auth } = useAuth();
  const [subscription, setSubscription] = useState();

  const GetListPages = async (unique_id) => {
    let irequestFilter = [];
    irequestFilter.push({ key: "business", value: `${unique_id}` });
    let getArticle = new DomainList();
    let response = await getArticle.filter(irequestFilter);

    let newData = [];
    for (const key in response.data) {
      if (response.data[key].is_active) {
        newData.push({
          text: response.data[key].name,
          url: response.data[key].link.replace("/", ""),
          id: key,
          //tag: response.data[key].tag,
        });
      }
    }
    setCreatorRouter(response.data);
    setCreatorRouter(newData);
  };

  const GetBusinessSub = () => {
    (async () => {
      const getSub = new BusinessSubscription();
      try {
        const response = await getSub.getAllAuth(logout);
        setSubscription(response.data);
      } catch (error) {
        console.error("Error al obtener la suscripción:", error);
      }
    })();
  };

  useEffect(() => {
    GetBusinessSub();
  }, []);

  const GetAPI = async () => {
    let useBusines = new BusinessManages();
    let response = await useBusines.getAllByIdAuth("1", logout);
    if (!response.data) return;
    setUnique_id(response?.data.unique_id);
  };

  useEffect(() => {
    if (auth) GetAPI();
  }, [auth]);

  useEffect(() => {
    if (unique_id) {
      GetListPages(unique_id);
    }
  }, [unique_id]);

  if (
    !creatorRouter &&
    localStorage.getItem("basepath") &&
    localStorage.getItem("token")
  ) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <CreatorContext.Provider
      value={{ creatorRouter, setCreatorRouter, unique_id, subscription }}
    >
      {children}
    </CreatorContext.Provider>
  );
};
