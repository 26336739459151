import * as React from "react";
import Box from "@mui/material/Box";
import "../scss/modal.css";
import Modal from "@mui/material/Modal";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Grid, Typography } from "@mui/material";
import Grow from "@mui/material/Grow";
import Backdrop from "@mui/material/Backdrop";

/* const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'auto',
  bgcolor: 'background.paper',
  borderRadius : '7px',
/*   border: '2px solid #000', 
  boxShadow: 24,
  p: 4,
};
 */
export default function BasicModal(props) {
  const { open, setOpen, children, sx } = props;

  return (
    <div>
      <Modal
        open={open || false}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          className="modal-height2"
        >
          <Grid
            className="box"
            item
            xs={12}
            sm={10}
            md={7}
            lg={6}
            xl={5}
            sx={{ bgcolor: "background.paper", ...sx }}
          >
            <Grid container justifyContent="flex-end" alignItems="center">
              <Grid item xs={11}>
                {props.title && (
                  <Typography
                    variant="h6"
                    gutterBottom
                    component="div"
                    sx={{ p: 0, m: 0 }}
                  >
                    <strong> {props.title}</strong>
                  </Typography>
                )}
              </Grid>
              <Grid item xs={1}>
                <Grid container justifyContent="flex-end" alignItems="center">
                  <IconButton
                    sx={{ p: 0, m: 0 }}
                    className="icon"
                    onClick={() => setOpen(false)}
                    aria-label="delete"
                  >
                    <CloseIcon sx={{ color: "text.primary", p: 0, m: 0 }} />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} mt={"16px"}>
              {children}
            </Grid>
          </Grid>
        </Grid>
      </Modal>
    </div>
  );
}
