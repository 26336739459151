"use client";
import {
  Button,
  Grid,
  IconButton,
  LinearProgress,
  linearProgressClasses,
  Menu,
  MenuItem,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import MoreHorizTwoToneIcon from "@mui/icons-material/MoreHorizTwoTone";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import BasicModal from "../../../components/GenericModal";
import { Dropzone, FileMosaic } from "@files-ui/react";
import { useAuth } from "user-context-fran-dev";
import { ProductExcelService } from "../../../api/productService";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[200],
    ...theme.applyStyles("dark", {
      backgroundColor: theme.palette.grey[800],
    }),
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: "#68C5D5",
    ...theme.applyStyles("dark", {
      backgroundColor: "#68C5D5",
    }),
  },
}));

const handleDownload = () => {
  const fileUrl = "/catalog_import_template.xlsx";
  const link = document.createElement("a");
  link.href = fileUrl;
  link.download = "catalog_import_template.xlsx";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const ImportDocs = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [openModal, setOpenModal] = useState(false);
  const [progress, setProgress] = useState(0);
  const [upload, setUpload] = useState("pending");
  const [fileStatus, setFileStatus] = useState({});
  const { logout } = useAuth();
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let interval;

    if (loading) {
      setProgress(0);
      interval = setInterval(() => {
        setProgress((prev) => (prev < 90 ? prev + 10 : prev));
      }, 500);
    }

    return () => clearInterval(interval);
  }, [loading]);

  const fetchFile = async () => {
    setLoading(true);
    setUpload("uploading");
    const productExcel = new ProductExcelService();
    const formData = new FormData();
    formData.append(`${files[0].name.split(".")[1]}`, files[0].file);

    try {
      const response = await productExcel.uploadImg(formData, logout);

      if (response.status !== 200) {
        cleanUpload();
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      setProgress(100);
      setFileStatus(response.data);
      setUpload("completed");
    } catch (error) {
      console.error("Request failed", error);
    } finally {
      setLoading(false);
    }
  };

  const downloadCSV = () => {
    const blob = new Blob([fileStatus.csv], { type: "text/csv" });
    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = "data.csv";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    URL.revokeObjectURL(url);
  };

  const cleanUpload = () => {
    setUpload("pending");
    setFiles([]);
    setFileStatus({});
    setProgress(0);
  };

  return (
    <>
      <div>
        <IconButton
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={(event) => setAnchorEl(event.currentTarget)}
          sx={{ p: 0, height: "100%" }}
        >
          <MoreHorizTwoToneIcon
            color="primary"
            sx={{
              border: "1px solid",
              borderRadius: 80,
              fontSize: "35px",
            }}
          />
        </IconButton>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={() => setAnchorEl(null)}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem onClick={() => setOpenModal(true)}>
            <Stack direction={"row"} spacing={1}>
              <FileDownloadOutlinedIcon />
              <Typography>Importar productos</Typography>
            </Stack>
          </MenuItem>
        </Menu>
      </div>
      <BasicModal
        open={openModal}
        setOpen={setOpenModal}
        sx={{
          width: "1116px",
          maxWidth: "none !important",
          flexBasis: "auto !important",
          height: "511px",
        }}
        title="Importar productos"
      >
        <Grid container spacing={3}>
          {upload !== "completed" ? (
            <>
              <Grid item xs={12}>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={handleDownload}
                  sx={{ borderRadius: "20px" }}
                >
                  Descargar archivo de ejemplo
                </Button>
              </Grid>
              <Grid item xs={12}>
                <p>
                  Datos opcionales: SKU, Código de barras, SAT Clave ProdServ,
                  SAT Clave Unidad, IVA Tasa (%), IEPS Tasa (%) Estas columnas
                  pueden quedar vacías.
                </p>
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={6}>
                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                  <strong>Resumen</strong>
                </Typography>
                <p>Productos: {fileStatus.success_count}</p>
                <p>Categorias: {fileStatus.new_categories}</p>
              </Grid>
              <Grid
                item
                xs={6}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                }}
              >
                {fileStatus.failed_count !== 0 && (
                  <>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={downloadCSV}
                      sx={{ borderRadius: "20px" }}
                    >
                      Descargar productos con errores
                    </Button>
                    <p style={{ color: "#D32F2F" }}>
                      {fileStatus.failed_count} Errores
                    </p>
                  </>
                )}
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <Dropzone
              onChange={(incommingFiles) => setFiles(incommingFiles)}
              value={files}
              accept=".xlsx,.xls,.csv"
              header={false}
              footer={false}
              clickable={upload === "pending"}
              maxFiles={1}
              label="Cargar archivo .xlsx, .xls o .csv"
              style={{ height: "228px" }}
            >
              {upload === "pending" && (
                <>
                  {files.length === 0 && (
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <p style={{ fontSize: "18px", color: "#000000" }}>
                          Cargar archivo .xlsx, .xls o .csv
                        </p>
                      </Grid>
                      <Grid item xs={12}>
                        <Button
                          variant="contained"
                          sx={{
                            borderRadius: "20px",
                            backgroundColor: "#68C5D5",
                            color: "#fff",
                          }}
                        >
                          Seleccionar archivo
                        </Button>
                      </Grid>
                    </Grid>
                  )}
                  {files.map((file) => (
                    <FileMosaic
                      key={file.id}
                      {...file}
                      onDelete={(id) =>
                        setFiles(files.filter((x) => x.id !== id))
                      }
                      info
                    />
                  ))}
                </>
              )}

              {upload === "uploading" && (
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <p style={{ fontSize: "18px", color: "#000000" }}>
                      Cargando productos...
                    </p>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <BorderLinearProgress
                      variant="determinate"
                      value={progress}
                      sx={{ width: "362px" }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <span style={{ fontSize: "14px", color: "#B9B9B9" }}>
                      No recarges la pagina ni cierres esta ventana
                    </span>
                  </Grid>
                </Grid>
              )}

              {upload === "completed" && (
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <p style={{ fontSize: "18px", color: "#000000" }}>
                      Productos guardados
                    </p>
                  </Grid>
                  <Grid item xs={12}>
                    <span style={{ fontSize: "14px", color: "#B9B9B9" }}>
                      No te olvides descargar productos con errores
                    </span>
                  </Grid>
                </Grid>
              )}
            </Dropzone>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              gap: "20px",
            }}
          >
            {upload !== "completed" ? (
              <>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => setOpenModal(false)}
                  sx={{ borderRadius: "20px" }}
                >
                  Cancelar
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={files.length === 0}
                  onClick={() => {
                    if (files.length === 0) return;
                    fetchFile();
                  }}
                  sx={{ borderRadius: "20px" }}
                >
                  Siguiente
                </Button>
              </>
            ) : (
              <Button
                variant="outlined"
                color="primary"
                onClick={cleanUpload}
                sx={{ borderRadius: "20px" }}
              >
                Volver
              </Button>
            )}
          </Grid>
        </Grid>
      </BasicModal>
    </>
  );
};
