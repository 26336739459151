import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import useDebounced from "./useDebounce";
import { useAuth } from "user-context-fran-dev";

export const useFilters = () => {
  const [params, setParams] = useSearchParams();

  const handleSearchEmpty = (event) => {
    if (event.target.value.length !== 0) {
      params.set(event.target.name, event.target.value);
      setParams(params);
    } else if (event.target.value.length === 0 || event.target.value === "") {
      params.delete(event.target.name);
      setParams(params);
    }
  };

  const handleSearch = (key, value) => {
    params.set(key, value);
    setParams(params);
  };

  return [params, setParams, handleSearch, handleSearchEmpty];
};

/* --------------------------- Para Manejar tablas con el page de manera automatica  ----------------------------------- */

export const useTableFilters = (
  service,
  setTable,
  setLoading,
  filter = null,
  avoid = "",
  debounce = 0
) => {
  const [params, setParams] = useSearchParams();
  const [searchState, setSearchState] = useState({}); // Temporary search state
  const { logout, auth } = useAuth();
  /* const AVOID = "tab" */
  const totalPerPage = 10;

  const useDebouseSearch = useDebounced(params, debounce);

  const handleInputChange = (event) => {
    if (!event.target) return;
    const { name, value } = event.target;
    setSearchState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSearch = () => {
    // Apply searchState values to params
    if (searchState) {
      Object.keys(searchState).forEach((key) => {
        if (searchState[key]) {
          params.set(key, searchState[key]);
        } else {
          params.delete(key);
        }
      });
    }

    // Si la página no está presente o si se necesita resetearla, se actualiza
    if (!params.has("page")) {
      params.set("page", 1); // Si no hay una página, la establece en la primera
    }

    setParams(new URLSearchParams(params)); // Actualiza los parámetros de la URL con los nuevos valores
  };
  const handleChange = (key, value) => {
    if (key === "page") {
      params.set(key, value); // Actualiza el parámetro de página en la URL
      setParams(new URLSearchParams(params)); // Sincroniza los parámetros de la URL con el estado
    }
  };

  const UdateTable = async () => {
    try {
      setLoading(true); // Indica que la tabla está cargando

      let irequestFilter = [];

      // Agrega los parámetros, excluyendo "avoid"
      params.forEach((value, key) => {
        if (key !== avoid) {
          irequestFilter.push({ key, value: `${value}` });
        }
      });

      // Agrega el número de elementos por página
      irequestFilter.push({ key: "items", value: `${totalPerPage}` });

      // Agrega filtros adicionales si existen
      if (filter) {
        filter.forEach((data) => {
          irequestFilter.push({ key: data.key, value: `${data.value}` });
        });
      }

      // Llama al servicio para filtrar los datos
      const getArticle = new service();
      const response = await getArticle.filter(irequestFilter, logout);

      // Actualiza la tabla con los datos obtenidos
      setTable(response.data);
    } catch (error) {
      console.error("Error updating the table:", error);
      // Aquí puedes manejar el error de forma más específica, como mostrar un mensaje al usuario
    } finally {
      setLoading(false); // Finaliza el estado de carga
    }
  };

  useEffect(() => {
    if (!params.has("page")) {
      params.set("page", 1);
      setParams(params);
    } else {
      UdateTable();
    }
  }, [params]);

  return [
    params,
    setParams,
    handleSearch,
    handleInputChange,
    UdateTable,
    handleChange,
  ];
};
