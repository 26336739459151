import React, { useState, useEffect, useMemo } from "react";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import { useAuth } from "user-context-fran-dev";

export const GenericSelectTable = ({ text, listService, idlist }) => {
  const [list, setList] = useState("");
  const [value, setValue] = useState("");
  const { logout } = useAuth();

  useEffect(() => {
    listRequest();
  }, []);

  const setChangesList = (id) => {
    idlist(id);
    setValue(id);
  };

  const listRequest = () => {
    (async () => {
      const getList = new listService();
      const response = await getList.getAllAuth(logout);
      setList(response.data);
    })();
  };

  return (
    <>
      {list && (
        <FormControl fullWidth variant="outlined" size="small">
          <InputLabel id="demo-simple-select-filled-label">{text}</InputLabel>
          <Select
            labelId="demo-simple-select-standard-label"
            label={text}
            value={value}
            onChange={(e) => setChangesList(e.target.value)}
          >
            <MenuItem value={""}>Todo</MenuItem>
            {list.map((data, index) => (
              <MenuItem key={index} value={data.id}>
                {data.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </>
  );
};

export const GenericSelectTableNew = ({
  text,
  listService,
  params,
  setParams,
  handleSearch,
  handleChanges,
  name,
}) => {
  const [list, setList] = useState("");
  const [value, setValue] = useState("");
  const { logout } = useAuth();
  const [inputValue, setInputValue] = useState(params.get(name) || "");

  useEffect(() => {
    listRequest();
  }, []);

  const handleInputChange = (event) => {
    const { value } = event.target;
    setInputValue(value); // Update local state on input change
    handleChanges(event); // Call the provided handleChanges function (if needed)
  };

  const handleClearSearch = () => {
    setInputValue(""); // Reset the input field to empty
    // Optionally, you can reset any search parameters in URL
    if (params.has(name)) {
      params.delete(name); // Remove the search parameter
      setParams(params); // Update the URL parameters
    }
  };

  const listRequest = () => {
    (async () => {
      const getList = new listService();
      const response = await getList.getAllAuth(logout);
      setList(response.data);
    })();
  };

  return (
    <>
      {list && (
        <FormControl
          sx={{ width: { xs: "45%", sm: "45%", md: "50%" } }}
          variant="outlined"
          size="small"
        >
          <InputLabel id="demo-simple-select-filled-label">{text}</InputLabel>
          <Select
            name={name}
            labelId="demo-simple-select-standard-label"
            label={text}
            value={inputValue || ""} // Bind to local state
            onChange={handleInputChange}
          >
            <MenuItem onClick={handleClearSearch}>Todo</MenuItem>
            {list.map((data, index) => (
              <MenuItem
                key={index}
                value={data.id}
                onClick={() => {
                  handleSearch();
                }}
              >
                {data.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </>
  );
};
