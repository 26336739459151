import React from 'react';
import MoreHorizTwoToneIcon from '@mui/icons-material/MoreHorizTwoTone';
import Eye from '@mui/icons-material/RemoveRedEyeOutlined';
import Shopping from '@mui/icons-material/ShoppingCartOutlined';
import Delete from '@mui/icons-material/DeleteOutlineOutlined';
import Edit from '@mui/icons-material/EditOutlined';
import { useNavigate } from "react-router-dom";
import { Box, Button, Grid, IconButton, Menu, MenuItem, Modal, Stack, Typography } from '@mui/material';
import { Suppliers } from '../../api/supplierService';
import CustomerService from '../../api/customerService';
import PurchaseService from '../../api/purchaseService';
import SalesService from '../../api/salesService';
import { ProductService } from '../../api/productService';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p:'16px',
};

export default function OptionButton(props) {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [openmodal, setOpenmodal] = React.useState(false);
  const ModalOpen = () => setOpenmodal(true);
  const ModalClose = () => setOpenmodal(false);
  const urlInit = {
    purches: `purchase/detail/${props.id}`,
    sales: `sale/detail/${props.id}`,
    transfer: `detail/${props.id}`, 
    adjustment: `detail/${props.id}`,
    scrap: `detail/${props.id}`,
    trans: `detail/${props.id}`,
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePreview = () => {
    props.openDrawer();
    handleClose();
  };

  const handleEdit = () => {
    window.localStorage.setItem("menu", props.menu);
    if (props.wh) {
      navigate(urlInit[props.type])
    }else{
      navigate(`detail/${props.id}`);
    }
    
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

 

  const handleDelete = async () => {
    const services = {
      supplier: new Suppliers(),
      customer: new CustomerService(),
      purches: new PurchaseService(),
      sales: new SalesService(),
      product: new ProductService ()
    };

    const service = services[props.type];
    try {
      await service.editDataAuth(props.id, { is_archived: true }, props.logout);
      if (props.UpdateInfo && props.type !=='product') {
        props.UpdateInfo();
      }else{
        window.location.reload();
      }
      ModalClose();
    } catch (error) {
      console.error("Error al eliminar:", error);
    }
  
  };

  return (
    <>
      <div>
        <IconButton
          id="basic-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          sx={{p:0}}
        >
          <MoreHorizTwoToneIcon
            color="primary"
            sx={{
              border: '2px solid',
              borderRadius: 80,
              fontSize:'30px'
            }} 
          />
        </IconButton>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <MenuItem onClick={handlePreview}>
            <Stack direction={'row'} spacing={1}>
              <Eye />
              <Typography>
                Vista previa
              </Typography>
            </Stack>
          </MenuItem>
          <MenuItem onClick={handleEdit}>
          {props.process ? (
          <Stack direction="row" spacing={1}>
          <Shopping />
          <Typography>Procesar</Typography>
        </Stack>
        ) : (
            <Stack direction="row" spacing={1}>
            <Edit />
            <Typography>Editar</Typography>
          </Stack>
        )}
          </MenuItem>
          <MenuItem 
          sx={{
            display:props.type === "sales" 
            || props.type === "purches"
            || props.type === "transfer"
            || props.wh
            ? 'none' : ''
          }}
          onClick={()=>{ModalOpen(); handleClose();}}>
            <Stack
              direction={'row'}
              spacing={1}
            >
              <Delete />
              <Typography>
                Eliminar
              </Typography>
            </Stack>
          </MenuItem>
        </Menu>
      </div>
      <Modal
        open={openmodal}
        onClose={ModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Eliminar 
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            ¿Estás seguro de que deseas eliminar este proveedor?
          </Typography>
          <Grid display={'flex'} justifyContent={'flex-end'}  mt={'24px'}>
          {[ 'Cancelar', 'Continuar'].map((title) => (
            <Button
              key={title}
              variant={title === "Continuar" ? 'contained' : 'outlined'}
              sx={{ mr: '8px' , borderRadius:60 }}
              onClick={() => {
                if (title === "Continuar") {
                  handleDelete();
                } else {
                  ModalClose();
                }
              }}
            >
              {title}
            </Button>
          ))}
        </Grid>
        </Box>
       
      </Modal>
    </>
  );
}
