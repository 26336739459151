import React, { useEffect, useState } from "react";
import { CircularProgress, Grid, Stack } from "@mui/material";
import { Typography } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import BasicTable from "../../../components/GenericTable";
import ArticleTable from "./articleTable";
import AddArticle from "./addArticle";
import ManageCategory from "./manageCategory";
import PurchaseService from "../../../api/productsManages";
import { GenericEmptyTable } from "../../../components/GenericEmptyTable";
import { TextField } from "@mui/material";
import PageParam from "../../../components/PageParam";
import { useTableFilters } from "../../../hooks/searchParams";
import { ArticleTableSearcher } from "../../../components/inventario/ArticleSearcher";
import { useAuth } from "user-context-fran-dev";
import { ImportDocs } from "./importDocs";

const Articles = () => {
  const { logout } = useAuth();
  const [loading, setLoading] = useState("");
  const [table, setTable] = useState();
  const [params, setParams, handleSearch, handleSearchEmpty, UdateTable] =
    useTableFilters(PurchaseService, setTable, setLoading);

  return (
    <>
      <Grid
        display={"flex"}
        flexDirection={{ xs: "column", md: "row" }}
        justifyContent={"space-between"}
        m={"24px 0"}
      >
        <Typography variant="h5" gutterBottom component="div">
          <strong>Productos</strong>
        </Typography>
        <Stack display={"flex"} direction={"row"} gap={"30px"}>
          {/* <ManageCategory /> */}
          <ImportDocs />
          <AddArticle udateProducts={UdateTable} />
        </Stack>
      </Grid>

      <Grid mb={"24px"}>
        <ArticleTableSearcher />
      </Grid>
    </>
  );
};

export default Articles;
